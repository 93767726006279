import React from "react"
const faQuestions = [
  {
    q: "What is the Millionaire Millennial Academy?",
    a: (
      <span>
        Millionaire Millennial Academy is an educational movement helping entrepreneurs
        advance their career by learning the skills and traits they must have to
        be successful.
        <br />
        <br />
        Filled with classes, tools, and mentors - The Millionaire Millennial Academy is
        availiable online anytime and anywhere you need it.
      </span>
    ),
  },
  {
    q: "What is included in a Millionaire Millennial Academy membership?",
    a: (
      <span>
        Membership grants you access to the entire library of 100+ lectures and
        tools designed specifically for your success as an entrepreneur.
      </span>
    ),
  },
  {
    q: "Is this right for me?",
    a: (
      <span>
        If you're an aspiring entrepreneur, a total noob, or already have a
        business and looking to enhance and improve your skillset, then yes,
        this is 100% for you.
      </span>
    ),
  },
  {
    q: "How much does a membership cost?",
    a: (
      <span>
        The monthly membership is $19 and gives you unlimited access to all
        programs, classes, and tools. You can also purchase individual courses
        and programs for a one time cost.
      </span>
    ),
  },
  {
    q: "How do I cancel?",
    a: (
      <span>
        If you find you're unable to continue with your membership, you can
        cancel anytime in Account ⇒ Billing . One time purchases carry a 30-day
        refund policy since they cannot be canceled (you have lifetime access
        for one-time purchases).
      </span>
    ),
  },
]
export default () => (
  <>
    <h2>❓&nbsp;Frequently Asked Questions</h2>
    <div className="faq-holder">
      <div className="tabs">
        {faQuestions.map((q, i) => (
          <div className="tab" key={i}>
            <input type="checkbox" id={`rd${i + 1}`} name="rd" />
            <label className="tab-label" htmlFor={`rd${i + 1}`}>
              {q.q}
            </label>
            <div className="tab-content">{q.a}</div>
          </div>
        ))}
        {/* <div className="tab">
        <input type="checkbox" id="rd30" name="rd"/>
        <label for="rd30" className="tab-close">Close &times;</label>
      </div> */}
      </div>
    </div>
  </>
)

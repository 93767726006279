import React from "react"

export default [
  {
    _id: 0,
    title: "Affiliate Overview",
    subtitle: "An overview ofthe entire affiliate system.",
    time: "14:03",
    vid_link: "410651810",
    desc: "",
  },
  {
    _id: 0.1,
    title: "Week 1: Prepare",
    desc: "",
  },
  {
    _id: 2,
    title: "Decide Your Niche",
    subtitle: "There's so many - how do you choose?",
    time: "7:31",
    vid_link: "410653907",
    desc: "",
  },
  {
    _id: 3,
    title: "Personal Brand vs Business Brand",
    subtitle: "Which is the best starting out?",
    time: "10:51",
    vid_link: "410657280",
    desc: "",
  },
  {
    _id: 4,
    title: "Picking Your Affiliation(s)",
    subtitle: "Building relationships instead of quick money.",
    time: "10:12",
    vid_link: "410658130",
    desc: "",
  },
  {
    _id: 5,
    title: "BONUS: Tools Needed",
    subtitle: "All the tools you'll need to run this business.",
    time: "9:07",
    desc: (
      <div>
        <h1>
          <strong>BONUS:</strong> Tools spoken about in this lecture...
        </h1>{" "}
        <p>
          *
          <em>
            note some of these are affiliate links (I practice what I teach). If
            you don&#x27;t want to use these links, that is <u>no problem</u>;
            simply use google search*
          </em>
        </p>{" "}
        <p>
          The tools I prefer/use are in <strong>bold. </strong>I have included 
          <em>subpar </em>alternatives as well.
        </p>{" "}
        <h2>Autoresponders:</h2>{" "}
        <p>
          <strong>ActiveCampaign</strong>: 
          <a href="https://www.activecampaign.com/?_r=1N96GXRE" target="_blank">
            https://www.activecampaign.com/?_r=1N96GXRE
          </a>
        </p>{" "}
        <p>
          <em>MailChimp</em>: 
          <a href="https://mailchimp.com/" target="_blank">
            https://mailchimp.com
          </a>
        </p>{" "}
        <h2>Facebook Messenger Bot:</h2>{" "}
        <p>
          <strong>ManyChat</strong>: 
          <a href="https://manychat.com/" target="_blank">
            https://manychat.com
          </a>
        </p>{" "}
        <p>
          <em>ChatFuel</em>: 
          <a href="https://chatfuel.com/" target="_blank">
            https://chatfuel.com
          </a>
        </p>{" "}
        <h2>Landing Page Creators:</h2>{" "}
        <p>
          <strong>ClickFunnels </strong>(14 day free trial): 
          <a
            href="https://mm.millionairemillennial.me/clickfunnels"
            target="_blank"
          >
            https://mm.millionairemillennial.me/clickfunnels
          </a>
        </p>{" "}
        <p>
          <em>LeadPages </em>(14 day free trial): 
          <a href="https://leadpages.pxf.io/RErGg" target="_blank">
            https://leadpages.pxf.io/RErGg
          </a>
        </p>
      </div>
    ),
    vid_link: "410662441",
  },
  {
    _id: 6,
    title: "Week 1 Worksheet",
    subtitle: "Your assignment for the first week.",
    time: "2:23",
    desc: (
      <a
        className="download"
        href="https://dufsi3fvwedfn.cloudfront.net/smam/SMAM+Week+1+Worksheet.pdf"
        target="_blank"
        rel="noopener"
        download=""
        data-x-origin-download=""
        data-x-origin-download-name="SMAM Week 1 Worksheet"
        data-x-origin-download-initialzed="true"
      >
        SMAM Week 1 Worksheet
      </a>
    ),
    vid_link: "410663746",
  },
  {
    _id: 6.1,
    title: "Week 2: Setup Infrastructure",
    desc: "",
  },
  {
    _id: 7,
    title: "What An Optin Page Is For",
    subtitle: "The tried and true method for connecting.",
    time: "8:06",
    vid_link: "410667745",
    desc: "",
  },
  {
    _id: 8,
    title: "Build An Optin Page",
    subtitle: "Constructing the money gateway.",
    time: "27:19",
    desc: (
      <div>
        <p>
          Namecheap: 
          <a href="https://www.namecheap.com/" target="_blank">
            https://www.namecheap.com/
          </a>
        </p>{" "}
        <p>
          Clickfunels: 
          <a
            href="http://mm.millionairemillennial.me/clickfunnels"
            target="_blank"
          >
            http://mm.millionairemillennial.me/clickfunnels
          </a>
        </p>{" "}
        <p>
          <a
            href="https://help.clickfunnels.com/hc/en-us/articles/360006338213-Namecheap-CNAME-Record"
            target="_blank"
          >
            Namecheap -&gt; Clickfunnels How To
          </a>
        </p>{" "}
        <p>
          Clickfunnels FB Page (super helpful): 
          <a
            href="https://www.facebook.com/groups/ClickFunnels/"
            target="_blank"
          >
            https://www.facebook.com/groups/ClickFunnels/
          </a>
        </p>
      </div>
    ),
    vid_link: "410670832",
  },
  {
    _id: 9,
    title: "Create a 'Lead Magnet': Theory & Purpose",
    subtitle: "Attracting boatloads of customers",
    time: "9:46",
    vid_link: "410695068",
    desc: "",
  },
  {
    _id: 10,
    title: "Create a 'Lead Magnet': Design & Build",
    subtitle: "Building your most important piece of content.",
    time: "16:00",
    vid_link: "410705787",
    desc: "",
  },
  {
    _id: 11,
    title: "Integrating Your Autoresponder",
    subtitle: "How to stay in the minds of your customers.",
    time: "11:50",
    desc: (
      <div>
        <p>More info on tags/lists:</p>{" "}
        <p>
          A <strong>list </strong>is an overall set of users (e.g.
          &quot;MM&#x27;s Tips &amp; Tricks&quot;, &quot;Atlas Labs&quot;,
          &quot;Supplement Brand&quot;, etc)
        </p>{" "}
        <p>
          A <strong>tag </strong>is then a <em>subset </em>of that list (e.g.
          &quot;!guide_optin&quot; for users that opted in to get a guide&quot;)
        </p>{" "}
        <p>
          Essentially, tags just further segment your &#x27;
          <strong>list</strong>&#x27;. To start, I would recommend only using 1
          list and multiple tags. 
          <u>You really only need multiple lists if you have multiple brands</u>
          , etc.
        </p>{" "}
        <p> </p>
      </div>
    ),
    vid_link: "410699776",
  },
  {
    _id: 12,
    title: "BONUS: 6 Templates For Opt-in Pages",
    subtitle: "The hottest opt-ins for maximum conversion rates.",
    time: "10:07",
    desc: (
      <ol>
        {" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7217995/share/5d2u8oahfyjowygk"
            target="_blank"
            rel="noreferrer"
          >
            Keto Guide Opt-in
          </a>
        </li>{" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7217999/share/g31b9e389cj5ozpx"
            target="_blank"
            rel="noreferrer"
          >
            Atlas Saas Opt-in
          </a>
        </li>{" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7218007/share/y16t92vugsdwmhso"
            target="_blank"
            rel="noreferrer"
          >
            200 Product List Opt-in
          </a>
        </li>{" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7218009/share/prh2j54gij8tm1wx"
            target="_blank"
            rel="noreferrer"
          >
            Clickfunnels Guide Opt-in
          </a>
        </li>{" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7218010/share/2x9xpr5ogewqubip"
            target="_blank"
            rel="noreferrer"
          >
            Amazon Guide Opt-in
          </a>
        </li>{" "}
        <li>
          <a
            href="https://app.clickfunnels.com/funnels/7218113/share/7eaw4oi5jyq182sf"
            target="_blank"
            rel="noreferrer"
          >
            Alex Becker SEO Opt-in
          </a>
        </li>{" "}
      </ol>
    ),
    vid_link: "410711850",
  },
  {
    _id: 13,
    title: "Week 2 Worksheet",
    subtitle: "Your assignment for the second week.",
    time: "3:18",
    desc: (
      <div>
        <div className="row attachment">Download</div>{" "}
        <div className="row attachment">
          <a
            className="download"
            href="https://dufsi3fvwedfn.cloudfront.net/smam/SMAM+Week+2+Worksheet.pdf"
            target="_blank"
            rel="noopener"
            download=""
            data-x-origin-download=""
            data-x-origin-download-name="SMAM Week 2 Worksheet.pdf"
            data-x-origin-download-initialzed="true"
          >
            SMAM Week 2 Worksheet
          </a>
        </div>
      </div>
    ),
    vid_link: "410715594",
  },
  {
    _id: 13.1,
    title: "Week 3: Conversations",
    desc: "",
  },
  {
    _id: 14,
    title: "Email Funnel Layout",
    subtitle: "How to direct people where they need to go.",
    time: "20:17",
    vid_link: "410716405",
    desc: "",
  },
  {
    _id: 15,
    title: "Finding Your Voice (Writing Emails)",
    subtitle: "Using the power of copywriting to move you audience.",
    time: "33:54",
    desc: (
      <div>
        <p>
          <a
            href="https://www.namecheap.com/support/knowledgebase/article.aspx/308/2214/how-to-set-up-free-email-forwarding"
            target="_blank"
            rel="noopener"
          >
            Forward incoming emails using NameCheap
          </a>
        </p>{" "}
        <p>
          <a
            href="https://medium.com/buildbuilds/how-to-hook-up-a-custom-domain-email-to-your-free-gmail-account-ead660884d11"
            target="_blank"
            rel="noopener"
          >
            Reply to emails (send using your custom domain from gmail)
          </a>
           (scroll down to: &quot;Additional steps to set up the sending
          from&quot;)
        </p>
      </div>
    ),
    vid_link: "410756553",
  },
  {
    _id: 16,
    title: "ManyChat",
    subtitle: "Tapping into the biggest audience on planet earth.",
    time: "30:22",
    vid_link: "410740847",
    desc: "",
  },
  {
    _id: 17,
    title: "Tying It All Together",
    subtitle: "Making sure all the pieces fit together.",
    time: "4:58",
    vid_link: "410734425",
    desc: "",
  },
  {
    _id: 19,
    title: "Week 3 Worksheet",
    subtitle: "Your assignment for the third week.",
    time: "3:11",
    desc: (
      <div>
        <div className="row attachment">Download</div>{" "}
        <div className="row attachment">
          <a
            className="download"
            href="https://dufsi3fvwedfn.cloudfront.net/smam/SMAM+Week+3+Worksheet.pdf"
            target="_blank"
            rel="noopener"
            download=""
            data-x-origin-download=""
            data-x-origin-download-name="SMAM Week 3 Worksheet.pdf"
            data-x-origin-download-initialzed="true"
          >
            SMAM Week 3 Worksheet
          </a>
        </div>
      </div>
    ),
    vid_link: "410737050",
  },
  {
    _id: 6.1,
    title: "Week 4: Content & Traffic",
    desc: "",
  },
  {
    _id: 20,
    title: "Traffic Sources",
    subtitle: "Where and how to get leads/customers.",
    time: "24:42",
    vid_link: "410757952",
    desc: "",
  },
  {
    _id: 21,
    title: "Creating YouTube Content",
    subtitle: "Utilizing long form content to fully connect.",
    time: "24:50",
    vid_link: "410786940",
    desc: "",
  },
  {
    _id: 22,
    title: "What Instagram Is All About",
    subtitle: "Integrating with the most visual social site",
    time: "17:56",
    desc: (
      <div>
        <p>Steps for Success on Instagram:</p>{" "}
        <ol>
          {" "}
          <li>
            Post 3 times per day with solid, engaging content (See what others
            in your niche post that is highly engaged with)
          </li>{" "}
          <li>
            Ensure using ~10 RELEVANT hashtags (#) per post that also have a
            decent search volume compared to others in the niche
          </li>{" "}
          <li>
            Comment REAL and engaging comments on 70+ posts per day. Really
            ensure these aren&#x27;t some copy/paste generic stuff. Make it real
            and make it personal.
          </li>{" "}
          <li>
            Do this for a few months and you will have REAL and engaged
            followers. No hacks, no buying likes/followers, no B.S.
          </li>{" "}
        </ol>
      </div>
    ),
    vid_link: "410767039",
  },
  {
    _id: 23,
    title: "BONUS: Short-Links ",
    subtitle: "Using your own domain for links!",
    time: "7:22",
    desc: (
      <div>
        <p>
          Short guide on setting up your A (address) record in{" "}
          <a
            href="https://www.namecheap.com/support/knowledgebase/article.aspx/319/2237/how-can-i-set-up-an-a-address-record-for-my-domain"
            target="_blank"
            rel="noopener"
          >
            Namecheap
          </a>
        </p>{" "}
        <p>
          Remember to use a <em>subdomain</em> like &#x27;link&#x27; or
          &#x27;app&#x27; etc instead of your main domain (or &#x27;www&#x27;)
        </p>
      </div>
    ),
    vid_link: "410739659",
  },
  {
    _id: 24,
    title: "Week 4 Worksheet",
    subtitle: "Your assignment for the fourth week.",
    time: "5:15",
    desc: (
      <div>
        <div className="row attachment">Download</div>{" "}
        <div className="row attachment">
          <a
            className="download"
            href="https://dufsi3fvwedfn.cloudfront.net/smam/SMAM+Week+4+Worksheet.pdf"
            target="_blank"
            rel="noopener"
            download=""
            data-x-origin-download=""
            data-x-origin-download-name="SMAM Week 4 Worksheet.pdf"
            data-x-origin-download-initialzed="true"
          >
            SMAM Week 4 Worksheet
          </a>
        </div>
      </div>
    ),
    vid_link: "410738512",
  },
]


import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { trackCustomEvent } from "../../utils/analytics"

export default ({ vidLink, noAutoPlay }) => {
  const [autoplayClicked, setAutoplayClicked] = React.useState(false)
  const images = useStaticQuery(graphql`
    query {
      soundOn: file(absolutePath: { regex: "/sound-on.png/" }) {
        childImageSharp {
          fluid(maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      playButton: file(absolutePath: { regex: "/play-button.png/" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  if (noAutoPlay)
    return (
      <div className="video-frame">
        <iframe
          src={`https://player.vimeo.com/video/${vidLink}`}
          width="100%"
          height="250"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    )
  else
    return (
      <div className="video-frame">
        <div
          hidden={autoplayClicked}
          className="video-overlay"
          onClick={() => {
            trackCustomEvent({
              category: "VSLs",
              action: "Play",
            })
            if (!autoplayClicked) setAutoplayClicked(true)
          }}
        >
          <Image
            fluid={images.soundOn.childImageSharp.fluid}
            alt="Turn on Sound"
            style={{ maxWidth: "30%", margin: "15px" }}
            className="sound-on-icon"
          />
          <Image
            fluid={images.playButton.childImageSharp.fluid}
            alt="Play"
            style={{ maxWidth: "25%", margin: "auto" }}
          />
        </div>
        <iframe
          src={`https://player.vimeo.com/video/${vidLink}${
            !autoplayClicked ? "?background=1" : "?autoplay=1"
          }`}
          width="100%"
          height="250"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      </div>
    )
}

import React from "react"
import { Form, FormState, firestore } from "gatsby-theme-firebase"
import { navigate } from "gatsby"

export default ({ isLoggedIn, course, slug, setToggleLogin }) => {
  let redirectLink = "/education/"
  if (slug) redirectLink = `/education/${slug}/content/`
  const fetchUser = async user => {
    const uid = user.user.uid

    let foundUser = await firestore
      .collection(`users`)
      .doc(uid)
      .get()

    user = foundUser.data()
    typeof window !== "undefined" &&
      localStorage.setItem("MM_User", JSON.stringify(user))
  }
  return (
    <>
      <div className="dark-overlay" />
      <div className="login-modal">
        <FormState.Provider>
          <Form
            setToggleLogin={setToggleLogin}
            isLoggedIn={isLoggedIn}
            course={course}
            onSignUpSuccess={user => {
              setToggleLogin(false)
              //fetchUser(user)
              navigate(redirectLink)
            }}
            onLoginSuccess={user => {
              setToggleLogin(false)
              //fetchUser(user)
              navigate(redirectLink)
            }}
            onResetSuccess={() => {}}
          />
        </FormState.Provider>
      </div>
    </>
  )
}

/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query MentorBioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            instagram
            youtube
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div>
      <i>
        <div
          style={{ fontSize: "1.9rem", margin: "1rem 0", fontWeight: "bolder" }}
        >
          Your Mentor:
        </div>
      </i>
      <div
        style={{
          display: `flex`,
          flexWrap: "wrap",
          marginBottom: 0,
        }}
      >
        <Image
          fluid={data.avatar.childImageSharp.fluid}
          alt={author}
          style={{
            margin: "auto 1rem",
            minWidth: 200,
            borderRadius: `100%`,
            flex: 2,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <div style={{ flex: 5 }}>
          <p>
            Hey! I'm Jordan, the&nbsp;<em>Millionaire Millennial</em>. Here to
            teach you everything you need to know about being successful in life
            and business. I've built&nbsp;<strong>multiple&nbsp;</strong>
            6-figure businesses and a&nbsp;<strong>7-figure</strong>
            &nbsp;business and I want to show you exactly how to as
            well!
          </p>
          <p>
            <strong>
              <u>
                <em>I teach exactly what I do</em>
              </u>
            </strong>
            ; that's how you know you're getting a good education.
          </p>
          <p>
            You can read more about me and my accomplishments on <Link to="/about">/about</Link>
          </p>
          Check me out on:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://instagram.com/${social.instagram}`}
          >
            Instagram
          </a>{" "}
          /{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://www.youtube.com/channel/${social.youtube}`}
          >
            YouTube
          </a>
        </div>
      </div>
    </div>
  )
}

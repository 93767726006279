import React from "react"
import Image from "gatsby-image"

export default ({ image,title,subtitle,button }) => {

  return (
    <div className="hero">
      <Image
        fluid={image}
        style={{ overflow: "hidden" }}
        imgStyle={{ opacity: 0.3, overflow: "hidden" }}
        alt={"hero"}
      />
      <div className="headline">
        <h1> {title}</h1>
        <h2 className="subtitle">{subtitle}</h2>
        <div style={{ maxWidth: "400px", margin: "1rem auto" }}>
         {button}
        </div>
      </div>
    </div>
  )
}

import React from "react"
import { navigate } from "gatsby"
import { trackCustomEvent } from "../../utils/analytics"

export default ({ slug, isLoggedIn, setToggleLogin, buttonText }) => {
  const ctaClick = () => {
    isLoggedIn ? navigate(`/education/${slug}/content/`) : setToggleLogin(true)
    trackCustomEvent({
      category: "Button",
      action: "CTA",
    })
  }
  return (
    <button className="cta-button" onClick={ctaClick}>
      {isLoggedIn ? "View Lectures" : buttonText ? buttonText : "Enroll Now"}
    </button>
  )
}

import React from "react"

export default props => {
  const { lectureInfo, chooseLecture, chosenLecture } = props
  return (
    <div className="lecture-list">
      {lectureInfo.map((lecture, i) => {
        if (lecture._id % 1 != 0) {
          // if this lecture is a section break
          return (
            <div key={i} className="lecture-list-section">
              <h3>{lecture.title}</h3>
            </div>
          )
        } else
          return (
            <div
              key={i}
              className={
                chosenLecture._id === lecture._id
                  ? "lecture-list-container selected"
                  : "lecture-list-container"
              }
              onClick={() => {
                if (chooseLecture) chooseLecture(lecture)
              }}
            >
              <div className="lecture-list-box">
                <div className="lecture-list-top">
                  <div className="lecture-list-title">{lecture.title}</div>
                  {lecture.time && (
                    <div className="lecture-list-time">{lecture.time}</div>
                  )}
                </div>
                <div className="lecture-list-subtitle">{lecture.subtitle}</div>
              </div>
            </div>
          )
      })}
    </div>
  )
}

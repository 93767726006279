import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"
import LoginModal from "../components/sales-pages/loginModal"
import Hero from "../components/sales-pages/hero"
import VSL from "../components/sales-pages/vsl"
import Bio from "../components/mentorBio"
import FAQ from "../components/faq"
import LiveChat from "../components/sales-pages/liveChat"
import CTAButton from "../components/sales-pages/ctaButton"
import LectureList from "../components/lectureList"
import { useStaticQuery, graphql } from "gatsby"
import { useAuth } from "gatsby-theme-firebase"
import "../styles/education.scss"
import "../styles/content.scss"
import publicLectureInfo from "../lectures/smam"

export default ({ pageContext: { course } }) => {
  const { title, subtitle, slug } = course
  const [toggleLogin, setToggleLogin] = React.useState(false)

  const { isLoggedIn } = useAuth()
  const images = useStaticQuery(graphql`
    query {
      hero: file(absolutePath: { regex: "/education/smam/smam-hero.jpeg/" }) {
        childImageSharp {
          fluid(maxWidth: 1920, maxHeight: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logo: file(absolutePath: { regex: "/education/consulting.jpg/" }) {
        childImageSharp {
          fixed {
            src
          }
        }
      }
      results: allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
          dir: { regex: "/education/smam/results/" }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const resultsImages = images.results.edges

  return (
    <Layout>
      <SEO
        title={`${title}: ${subtitle}`}
        description={subtitle}
        image={images.logo.childImageSharp.fixed.src}
      />
      {toggleLogin && (
        <LoginModal
          isLoggedIn={isLoggedIn}
          course={course}
          slug={slug}
          setToggleLogin={setToggleLogin}
        />
      )}
      <Hero
        image={images.hero.childImageSharp.fluid}
        title={title}
        subtitle={subtitle}
        button={
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        }
      />{" "}
      <div className="course-sales-content">
        <div className="row">
          <div className="column50">
            <VSL vidLink="417749277" />
          </div>
          <div className="column50">
            <p className="video-explainer">
              Hey 👋 it's Jordan, the&nbsp;<em>Millionaire Millennial</em>. You
              probably know by now I don't beat around the bush, I make high
              quality educational content, and (most importantly)
              <strong>
                &nbsp;<u>I get my students results</u>
              </strong>
              . Your success is my success.
              <CTAButton
                isLoggedIn={isLoggedIn}
                slug={slug}
                setToggleLogin={setToggleLogin}
              />
            </p>
          </div>
        </div>
        <div className="row" id="content">
          <p>
            The goal of this program is to prepare you and guide you to
            create&nbsp;<strong>sustainable&nbsp;</strong>income
            leveraging&nbsp;<em>social media</em>&nbsp;and other people&rsquo;s
            brands/companies. You will do this by creating your own affiliate
            brand that establishes itself as an expert or point of contact in a
            particular field.
          </p>
          <p>
            <strong>
              Please note: You do&nbsp;<u>NOT</u>&nbsp;have to be an expert when
              you start this program; in fact, this program is designed from the
              viewpoint that you&nbsp;<em>aren't</em>. However, through this
              program you will become an expert while earning some
              nice&nbsp;$$$&nbsp;on the way.
            </strong>
          </p>
          <p>
            This program is divided into&nbsp;<em>4 weeks</em>&nbsp;each with
            their own guided lectures and worksheets.
          </p>
          <p>In this program you will learn:</p>
          <ul className="fancy-checks">
            <li>
              Introduction to&nbsp;<strong>S.M.A.M.</strong>&nbsp;(Social Media
              Affiliate Marketing).
              <ul className="fancy-checks">
                <li>
                  What is the &lsquo;Old&rsquo; way to do affiliate marketing?
                </li>
                <li>
                  What is &lsquo;Scam Marketing&rsquo; and why it just
                  doesn&rsquo;t work anymore.
                </li>
                <li>Why the best option is S.M.A.M.</li>
              </ul>
            </li>
            <li>
              Week 1: Prepare
              <ul className="fancy-checks">
                <li>
                  All about the &lsquo;new&rsquo; sales funnel for affiliates
                  using S.M.A.M.
                </li>
                <li>
                  How to decide you niche for the&nbsp;<u>ultimate payday</u>
                </li>
                <li>What kind of brand should you be?</li>
                <li>
                  How to pick your affiliations&nbsp;<strong>wisely</strong>.
                </li>
                <li>
                  <strong>BONUS</strong>:&nbsp;
                  <em>All the tools you need to run this show.</em>
                </li>
              </ul>
            </li>
            <li>
              Week 2: Setup Infrastructure
              <ul className="fancy-checks">
                <li>The purpose of an opt-in page for affiliates.</li>
                <li>
                  How to create a lead magnet that attracts the best (
                  <strong>
                    <em>and highest paying</em>
                  </strong>
                  ) customers.
                </li>
                <li>
                  How to&nbsp;<strong>actually build</strong>&nbsp;your opt-in
                  page (no coding required!).
                </li>
                <li>
                  Creating and connecting your&nbsp;<em>Autoresponder</em>.
                </li>
                <li>
                  <strong>BONUS</strong>:&nbsp;
                  <em>6 Templates for opt-in pages.</em>
                </li>
              </ul>
            </li>
            <li>
              Week 3: Conversations
              <ul className="fancy-checks">
                <li>
                  Understanding the layout of a&nbsp;<u>massively successful</u>
                  &nbsp;email campaign.
                </li>
                <li>
                  Finding your voice: how to speak in a way that&nbsp;
                  <strong>provokes interaction</strong>.
                </li>
                <li>
                  Setting up Facebook Messenger&rsquo;s 'autoresponder':&nbsp;
                  <u>ManyChat</u>
                </li>
                {/* <li> moved to bonuses
                  <strong>BONUS</strong>:&nbsp;
                  <em>ManyChat template created for 90% engagement</em>
                </li> */}
              </ul>
            </li>
            <li>
              Week 4: Content &amp; Traffic
              <ul className="fancy-checks">
                <li>The best traffic sources to tap into.</li>
                <li>
                  Building<em>&nbsp;long form</em>&nbsp;content for YouTube.
                </li>
                <li>
                  Crafting&nbsp;<em>engaging&nbsp;</em>content for Instagram.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <hr />
        <div className="row" id="results">
          <p>
            Let me show you real quick some of what is attainable with S.M.A.M.
            Below you'll find some payouts from other people using the S.M.A.M.
            method (including myself!)
          </p>
          {resultsImages.map((resultImage, i) => {
            return (
              <Image
                key={i}
                fluid={resultImage.node.childImageSharp.fluid}
                style={{ width: "80%", margin: "auto" }}
              />
            )
          })}
        </div>
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
        <br />
        <br />
        <hr />
        <div className="row">
          <Bio />
        </div>
        <br />
        <hr />
        <br />
        <h2>📚&nbsp;Course Curriculum</h2>
        <LectureList
          lectureInfo={publicLectureInfo}
          chooseLecture={null}
          chosenLecture={{}}
        />{" "}
        <hr />
        <br />
        <FAQ />
        <br />
        <hr />
        <div style={{ maxWidth: "400px", margin: "4rem auto" }}>
          <CTAButton
            isLoggedIn={isLoggedIn}
            slug={slug}
            setToggleLogin={setToggleLogin}
          />
        </div>
      </div>
      <LiveChat />
    </Layout>
  )
}
